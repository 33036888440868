export const navigation = (userPermissions: any) => {
	return [
		{
			text: 'Inicio',
			path: '/home',
			icon: 'mdi mdi-home',
		},
		{
			text: 'Usuarios',
			icon: 'mdi mdi-account-multiple',
			path: '/users',
			disabled: !userPermissions.canViewUserList,
		},
		{
			text: 'Roles',
			icon: 'mdi mdi-checkbox-multiple-marked',
			path: '/roles',
		},
		{
			text: 'Documentación',
			icon: 'mdi mdi-text-box-multiple',
			expanded: true,
			items: [
				{
					text: 'Schemas',
					path: '/documentation/open-banking/schemas',
				},
			],
		},
		{
			text: 'API Keys',
			icon: 'mdi mdi-key',
			path: '/apikeys',
			disabled: !userPermissions.canViewApiKeyList,
		},
		{
			text: "Consulta de operaciones",
			icon: "mdi mdi-list-box",
			path: '/operations',
		}
	];
};
