export const statusLookup = [
	{ value: 'ACTIVE', text: 'Activo' },
	{ value: 'INACTIVE', text: 'Inactivo' },
	{ value: 'BLOCKED', text: 'Bloqueado' },
];

export const statusLookupRoles = [
	{ value: 'ACTIVO', text: 'Activo' },
	{ value: 'INACTIVO', text: 'Inactivo' },
	{ value: 'BLOQUEADO', text: 'Bloqueado' },
];
