import React from 'react';
import './apiKeys.scss';
import { Link, useLocation } from 'react-router-dom';
import { Button, TextBox } from 'devextreme-react';
import { CopyClipboard } from '../../utils/clipboard';

export default function ApiKeysScreen() {
	const location = useLocation();
	const { apiKey } = location.state;
	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb'}>
				<Link to={'/apikeys'}>API Keys</Link>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">Crear Nuevo</span>
			</h2>
			<div className={'content-block'}>
				<div
					className={
						'dx-card responsive-paddings animate__animated animate__fadeIn'
					}
				>
					<div className="warning-card">
						<i className="mdi mdi-alert-outline" />
						<p className="warning-text-box">
							Por favor asegurése de copiar el api key y guardarlo
							en un lugar seguro. Nosotros no lo almacenamos, y ya
							no podrá volver a verlo.
						</p>
					</div>
					<div className="box-content">
						<TextBox className='api-key-box' defaultValue={apiKey} />
						<Button
							icon="mdi mdi-content-copy"
							text="Copiar"
							onClick={() => CopyClipboard(apiKey)}
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
