import React from 'react';
import { DataGridOperations } from './dataGridOperations';

export default function Operations() {
	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb actual'}>Consulta de operaciones</h2>
			<div className={'content-block'}>
				<div
					className={
						'dx-card responsive-paddings animate__animated animate__fadeIn'
					}
				>
					<DataGridOperations />
				</div>
			</div>
		</React.Fragment>
	);
}