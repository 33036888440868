export const getInitialApiKeysFormData = (apiKeyData: any = {}) => {
	return {
		name: apiKeyData.name,
		description: apiKeyData.description,
		status: apiKeyData.status === 'ACTIVE' ? 'ACTIVO' : 'INACTIVO',
	};
};

export const initialApiKeysErrors = {
	name: '',
};

export const handleClearForm = (setFormData: Function) => () => {
	setFormData(getInitialApiKeysFormData({}));
};

export const validate = (formData: any) => {
	const newErrors = {} as any;
	if (!formData.name) newErrors.name = 'El nombre es requerido';
	return newErrors;
};
