/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
	Item,
	Label,
	ButtonItem,
	ButtonOptions,
	RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';import { useAuth } from '../../contexts/auth';
import './LoginForm.scss';
import { defaultShowTimeSToast } from '../../config/globalVariables';
import { usePasswordEditorOptions, useAddAppLoginClass } from '../form-commons/FormCommons';
import { showNotification } from '../../utils/showNotification';
import { UiConfigContext } from '../../contexts/uiConfig';

export default function LoginForm() {
	const { signIn } = useAuth();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const formData = useRef({ email: '', password: '' });
	const passwordEditorOptions = usePasswordEditorOptions();
	const { uiConfig } = useContext(UiConfigContext);
	const onSubmit = useCallback(
		async (e: any) => {
			e.preventDefault();
			const { email, password } = formData.current;
			setLoading(true);

			const result = await signIn(email, password);
			if (!result.isOk) {
				setLoading(false);
				showNotification({ message: result.message!, type: 'error', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
			}
			else {
				navigate('/home');
			}
		},
		[signIn]
	);

	// efecto para sobreescribir el background del login con el general
	useAddAppLoginClass();

	let emailEditorOptions = {
		stylingMode: 'outlined',
		labelMode: 'outside',
		mode: 'text',
		height: '50px',
		showClearButton: false,
	};

	let rememberMeEditorOptions = {
		text: 'Recordarme',
		elementAttr: { class: 'form-text' },
	};

	// ese useEffect se ejecuta solo la primera vez que se monta la pagina,
	// deja que la animacion de animate.css se ejecute 1 vez y luego elimina las clases necesarias
	const [hasAnimated, setHasAnimated] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			const loginCard = document.querySelector('.login-form');
			if (loginCard === null) return;

			loginCard
				.querySelectorAll('.item-login_animated')
				.forEach((el) => el.classList.remove('animate__animated'));

			setHasAnimated(true);
		}, 1500);
	}, []);

	return (
		<form className={'login-form'} onSubmit={onSubmit}>
			<Form
				formData={formData.current}
				disabled={loading}
				showValidationSummary={false}
			>
				<Item
					dataField="email" // Usar nombres constantes para dataField que correspondan con las propiedades en formData
					editorType={'dxTextBox'}
					editorOptions={emailEditorOptions}
					cssClass={
						'item-login_animated textbox-login-label' +
						(!hasAnimated
							? 'animate__animated animate__flipInX animate__delay-1s'
							: '')
					}
				>
					<RequiredRule message="El usuario es requerido" />
					<Label visible={false} text="Usuario" />
				</Item>
				<Item
					dataField="password" // Este debería ser constante y no cambiar según el tipo de input
					editorType={'dxTextBox'}
					editorOptions={passwordEditorOptions}
					cssClass={
						'item-login_animated textbox-login-label' +
						(!hasAnimated
							? 'animate__animated animate__flipInX animate__delay-1s'
							: '')
					}
				>
					<RequiredRule message="La contraseña es requerida" />
					<Label visible={false} text="Contraseña" />
				</Item>
				<Item
					dataField={'rememberMe'}
					editorType={'dxCheckBox'}
					editorOptions={rememberMeEditorOptions}
					visible={false}
					cssClass="login-item-buttom"
				>
					<Label visible={false} />
				</Item>
				<ButtonItem
					cssClass={
						'item-login_animated login-item-buttom ' +
						(!hasAnimated
							? 'animate__animated animate__flipInX animate__delay-1s'
							: '')
					}
				>
					<ButtonOptions
						width={'100%'}
						height={'50px'}
						type={'default'}
						useSubmitBehavior={true}
					>
						<span className="dx-button-text">
							{loading ? (
								<LoadIndicator
									width={'24px'}
									height={'24px'}
									visible={true}
								/>
							) : (
								'INGRESAR'
							)}
						</span>
					</ButtonOptions>
				</ButtonItem>
				<Item cssClass="item-forgot-passwrod" visible={false}>
					<div
						className={
							'link forgot-password item-login_animated ' +
							(!hasAnimated
								? 'animate__animated animate__flipInX animate__delay-1s'
								: '')
						}
					>
						<Link to={'/reset-password'}>
							¿Olvidaste tu contraseña?
						</Link>
					</div>
				</Item>
			</Form>
		</form>
	);
}
