import { AuthValidate } from '../types/authValidateResponse';
import { RequestCode2FaInfo, LoginResponse, ValidateCode2FaInfo } from '../types/loginResponse';
import axios from 'axios';
import { getUserHeaders } from './config';

export async function signIn(userValue: string, password: string) {
	try {
		let deviceId = await getDeviceId();
		// Send request
		const response = await axios.post<LoginResponse>(
			'auth',
			{
				deviceId,
				userValue,
				password,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);

		sessionStorage.setItem(
			'user_access_token',
			response.data.userSession.accessToken
		);

		sessionStorage.setItem(
			'realm_access_token',
			response.data.userRealm.accessToken
		);
		return {
			isOk: true,
			data: {
				isFirstLogin: response.data.userSession.session.isFirstLogin,
				main2fa:
					response.data.userSession.session.userInfo.main2FA?.main2FA,
				email: response.data.userSession.session.userInfo.email,
				userValue: response.data.userSession.session.userValue,
				fullName: response.data.userSession.session.userInfo.fullName,
				actions: response.data.userRealm.session.actions,
				phone: response.data.userSession.session.userInfo.additionalInfo?.phone,
			},
		};
	} catch (error) {
		console.error(error);
		return {
			isOk: false,
			message:
				'No se ha podido loguear al sistema, consulte con el administrador',
		};
	}
}

export async function getUser() {
	try {
		// Send request
		const response = await axios.get<AuthValidate>('auth/validate', {
			headers: getUserHeaders(),
		});
		return {
			isOk: true,
			data: {
				isFirstLogin: response.data.isFirstLogin,
				main2fa: response.data.userInfo.main2FA?.main2FA,
				email: response.data.userInfo.email,
				userValue: response.data.userValue,
				fullName: response.data.userInfo.fullName,
				actions: response.data.actions,
				phone: response.data.userInfo.additionalInfo?.phone,
			},
		};
	} catch (error) {
		console.error(error);
		return {
			isOk: false,
			message:
				'No se ha podido obtener la información del usuario, consulte con el administrador',
		};
	}
}

async function getDeviceId() {
	const key = 'device_id';
	let deviceId: string | null = null;

	const userAgent = navigator.userAgent;
	console.log(userAgent);

	deviceId = localStorage.getItem(key);

	if (deviceId === null) {
		deviceId = crypto.randomUUID();
	}

	await axios
		.post<any>(
			'device',
			{
				deviceId,
				userAgent,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		.then(() => {
			localStorage.setItem(key, deviceId as string);
			console.log('Se registró correctamente el deviceId');
		})
		.catch((exception: any) => {
			console.error(exception);
			throw exception;
		});

	return deviceId;
}

export async function createAccount(userValue: string, password: string) {
	try {
		// Send request
		console.log(userValue, password);

		return {
			isOk: true,
		};
	} catch {
		return {
			isOk: false,
			message: 'No se ha podido crear la cuenta',
		};
	}
}

export async function changePassword(userValue: string, recoveryCode?: string) {
	try {
		// Send request
		console.log(userValue, recoveryCode);

		return {
			isOk: true,
		};
	} catch (error) {
		console.error(error);
		return {
			isOk: false,
			message: 'No se ha podido cambiar la contraseña',
		};
	}
}

export async function resetPassword(userValue: string) {
	try {
		// Send request
		console.log(userValue);

		return {
			isOk: true,
		};
	} catch (error) {
		console.error(error);
		return {
			isOk: false,
			message: 'No se ha podido resetear la contraseña',
		};
	}
}

export async function request2FaCode(payload: RequestCode2FaInfo) {
	try {
		const response = await axios.post(
			'/auth/2fa/request',
			payload,
			{ headers: getUserHeaders() }
		);
		const token = response.data.token || null;
		return {
			isOk: true,
			message: 'el request se hizo con exito',
			token: token,
		};
	} catch (error) {
		console.error(error);
		return {
			isOk: false,
			message: 'el request no se hizo',
		};
	}
}

export async function validate2FaCode(payload: ValidateCode2FaInfo) {
	try {
		const response = await axios.post(
			'/auth/2fa/validate',
			payload,
			{ headers: getUserHeaders() }
		);
		const status = response.data.status;
		return {
			success: true,
			message: 'el validate se hizo con exito',
			status: status,
		};
	} catch (error) {
		console.error(error);
		let errorMessage = "Error"
		if (axios.isAxiosError(error)) {
			errorMessage = error.response?.data.message;
		}
		return {
			success: false,
			message: errorMessage,
		};
	}
}


export async function newPasswordChange(password: string) {
	try {
		const response = await axios.patch(
			'/auth/password/change',
			{ newPassword: password },
			{ headers: getUserHeaders() },
		);
		return {
			success: true,
			message: 'La contraseña fue cambiada con éxito',
			response: response.data,
		};
	} catch {
		return {
			success: false,
			message: 'No se pudo cambiar la contraseña',
		};
	}
}
