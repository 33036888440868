import React, { useState, useMemo, useContext } from 'react';
import { Button, TextBox } from 'devextreme-react';
import { TextBoxTypes, Button as TextBoxButton } from 'devextreme-react/text-box';
import { ButtonTypes } from 'devextreme-react/button';
import Validator, { RequiredRule, PatternRule } from 'devextreme-react/validator';
import { useNavigate } from 'react-router-dom';
import { validateNewPassword } from '../../api/users';
import { defaultShowTimeSToast } from '../../config/globalVariables';
import './newPasswordForm.scss';
import { showNotification } from '../../utils/showNotification';
import { UiConfigContext } from '../../contexts/uiConfig';

export default function NewPasswordForm() {
	const [formData, setFormData] = useState({
		oldPassword: '',
		newPassword: '',
		confirmNewPassword: '',
	});
	const { uiConfig } = useContext(UiConfigContext);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const navigate = useNavigate();
	const pswPattern =
		'^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@$!%*?&#-])[A-Za-z0-9@$!%*?&#-]{8,30}$';
	const [oldPasswordMode, setOldPasswordMode] =
		useState<TextBoxTypes.TextBoxType>('password');
	const [newPasswordMode, setNewPasswordMode] =
		useState<TextBoxTypes.TextBoxType>('password');
	const [confirmPasswordMode, setConfirmPasswordMode] =
		useState<TextBoxTypes.TextBoxType>('password');

	const oldPasswordButton = useMemo<ButtonTypes.Properties>(
		() => ({
			icon: oldPasswordMode === 'text' ? 'eyeopen' : 'eyeclose',
			stylingMode: 'text',
			onClick: () => {
				setOldPasswordMode(oldPasswordMode === 'text' ? 'password' : 'text');
			},
		}),
		[oldPasswordMode]
	);

	const newPasswordButton = useMemo<ButtonTypes.Properties>(
		() => ({
			icon: newPasswordMode === 'text' ? 'eyeopen' : 'eyeclose',
			stylingMode: 'text',
			onClick: () => {
				setNewPasswordMode(newPasswordMode === 'text' ? 'password' : 'text');
			},
		}),
		[newPasswordMode]
	);

	const confirmPasswordButton = useMemo<ButtonTypes.Properties>(
		() => ({
			icon: confirmPasswordMode === 'text' ? 'eyeopen' : 'eyeclose',
			stylingMode: 'text',
			onClick: () => {
				setConfirmPasswordMode(confirmPasswordMode === 'text' ? 'password' : 'text');
			},
		}),
		[confirmPasswordMode]
	);

	const handleNewPasswordValidation = async (oldPassword: string, newPassword: string) => {
		setIsSubmitting(true);
		const deviceId = localStorage.getItem('device_id');
		console.log('deviceId: ', deviceId);
		const validateNewPasswordPayload = {
			deviceId: deviceId ?? '',
			oldPassword: oldPassword,
			newPassword: newPassword,
		};
		try {
			const result = await validateNewPassword(validateNewPasswordPayload);
			if (result.success) {
				showNotification({ message: result.message, type: 'success', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
				navigate('/home');
			} else {
				showNotification({ message: result.message, type: 'error', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
			}
		} catch (error) {
			console.error('Error al cambiar contraseña', error);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		const oldPassword = formData.oldPassword;
		const newPassword = formData.newPassword;
		const confirmNewPassword = formData.confirmNewPassword;
		console.log('FormData: ', formData);
		if (newPassword === confirmNewPassword) {
			handleNewPasswordValidation(oldPassword, newPassword);
		} else {
			showNotification({
				message: 'Las contraseñas no coinciden ',
				type: 'error',
				displayTime: defaultShowTimeSToast,
				position: uiConfig.notificationsPosition,
			});
		}
	};

	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb'}>Cambiar contraseña</h2>
			<div className={'content-block new-password'}>
				<div className={'dx-card responsive-paddings animate__animated animate__fadeIn'}>
					<div className="change-password-container">
						<div className="info-block">
							<span className="info-title">Información importante</span>
							<p>La contraseña debe contener:</p>
							<ul>
								<li>Más de 8 y menos de 30 caractéres</li>
								<li>Al menos un número</li>
								<li>
									Al menos una letra en mayúsculas y una en
									minúsculas
								</li>
								<li>Al menos uno de estos símbolos @$!%*?&#-</li>
							</ul>
						</div>
						<form className="form-block" onSubmit={handleSubmit}>
							<TextBox
								label="Contraseña actual"
								value={formData.oldPassword}
								mode={oldPasswordMode}
								labelMode="outside"
								stylingMode="filled"
								className="text-box"
								onValueChanged={(e) =>
									setFormData({
										...formData,
										oldPassword: e.value,
									})
								}
							>
								<TextBoxButton name="password" location="after" options={oldPasswordButton} />
								<Validator>
									<RequiredRule message="La contraseña es requerida" />
								</Validator>
							</TextBox>
							<TextBox
								label="Nueva contraseña"
								value={formData.newPassword}
								mode={newPasswordMode}
								labelMode="outside"
								stylingMode="filled"
								className="text-box"
								onValueChanged={(e) =>
									setFormData({
										...formData,
										newPassword: e.value,
									})
								}
							>
								<TextBoxButton name="password" location="after" options={newPasswordButton} />
								<Validator>
									<RequiredRule message="La contraseña es requerida" />
									<PatternRule pattern={pswPattern} message="La contraseña debe cumplir con los requisitos" />
								</Validator>
							</TextBox>
							<TextBox
								label="Confirmar nueva contraseña"
								value={formData.confirmNewPassword}
								mode={confirmPasswordMode}
								labelMode="outside"
								stylingMode="filled"
								onValueChanged={(e) =>
									setFormData({
										...formData,
										confirmNewPassword: e.value,
									})
								}
							>
								<TextBoxButton name="password" location="after" options={confirmPasswordButton} />
								<Validator>
									<RequiredRule message="La contraseña es requerida" />
									<PatternRule pattern={pswPattern} message="La contraseña debe cumplir con los requisitos" />
								</Validator>
							</TextBox>
							<br />
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									gap: '0.5rem',
								}}
							>
								<Button
									text="CAMBIAR CONTRASEÑA"
									className="finnekta-button-primary-action"
									useSubmitBehavior={true}
									disabled={isSubmitting}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
