import DataSource from 'devextreme/data/data_source';
import { getRealmHeaders, getUserHeaders } from '../../api/config';
import { apiBaseUrl } from '../../config/globalVariables';
import { showNotification } from '../../utils/showNotification';
import { DocumentType } from '../../types';

const uiConfigString = localStorage.getItem('ui_config');
const uiConfig = uiConfigString ? JSON.parse(uiConfigString) : null;

const notificationsPosition = uiConfig
	? uiConfig?.notificationsPosition
	: uiConfig?.DefaultUiConfig?.notificationsPosition;

export const documentsTypesDataSource = new DataSource<DocumentType>({
	store: {
		version: 4,
		type: 'odata',
		key: 'id',
		url: `${apiBaseUrl}documenttypes`,
		errorHandler: function (error: any) {
			console.log(error.httpStatus);
			if (error.httpStatus === 401) {
				showNotification({
					message:
						'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
					type: 'error',
					displayTime: 12000,
					position: notificationsPosition,
				});
			}
		},
	},
	select: ['id', 'description'],
});

export const rolesDataSource = {
	store: {
		version: 4,
		type: 'odata',
		key: 'id',
		url: `${apiBaseUrl}roles`,
		beforeSend: function (request: any) {
			request.headers = {
				...getRealmHeaders(),
				...getUserHeaders(),
			};
		},
		errorHandler: function (error: any) {
			console.log(error.httpStatus);
			if (error.httpStatus === 401) {
				showNotification({
					message:
						'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
					type: 'error',
					displayTime: 12000,
					position: notificationsPosition,
				});
			}
		},
	},
	select: ['id', 'nombre', 'descripcion', 'tipo', 'estado'],
};

export const getInitialFormData = (userData: any = {}) => {
	return {
		fullName: userData.fullName,
		email: userData.email,
		phone: userData.phone,
		documentType: userData.documentType,
		documentNumber: userData.documentNumber,
		userValue: userData.userValue,
		roles: userData.roles,
	};
};

export const initialErrors = {
	fullName: '',
	email: '',
	documentType: '',
	documentNumber: '',
	userValue: '',
	phone: '',
	roles: '',
};

export const handleInputChange =
	(setFormData: Function, setErrors: Function) =>
	(field: any, value: any) => {
		console.log('field', field, value);
		setFormData((prevFormData: any) => ({
			...prevFormData,
			[field]: value,
		}));
		setErrors((prevErrors: any) => ({
			...prevErrors,
			[field]: '',
		}));
	};

export const handleClearForm = (setFormData: Function) => () => {
	setFormData(getInitialFormData({}));
};

export const validate = (formData: any) => {
	const newErrors = {} as any;
	if (!formData.fullName)
		newErrors.fullName = 'El nombre completo es requerido';
	if (!formData.email) {
		newErrors.email = 'El correo electrónico es requerido';
	} else {
		const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
		if (!emailRegex.test(formData.email)) {
			newErrors.email = 'El correo electrónico no es válido';
		}
	}
	if (!formData.phone) {
		newErrors.phone = 'El número de celular es requerido';
	} else {
		const phoneRegex = /^09[6789]\d{7}$/;
		if (!phoneRegex.test(formData.phone)) {
			newErrors.phone =
				'El número de celular debe tener alguno de estos formatos 098,097,096,099';
		}
	}
	if (!formData.documentType)
		newErrors.documentType = 'El tipo de documento es requerido';
	if (!formData.documentNumber)
		newErrors.documentNumber = 'El número de documento es requerido';
	if (!formData.roles || formData.roles.length === 0) {
		newErrors.roles = 'Debe asignar al menos un rol';
	}
	return newErrors;
};
