import React, {
	useEffect,
	useRef,
	useCallback,
	useMemo,
	useState,
} from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import './SideNavigationMenu.scss';
import type { SideNavigationMenuProps } from '../../types';
import { useNavigate } from 'react-router-dom';
import * as events from 'devextreme/events';
import { useAuth } from '../../contexts/auth';
import { getUserPermissions } from '../../config/actions';

export default function SideNavigationMenu(
	props: React.PropsWithChildren<SideNavigationMenuProps>
) {
	const {
		children,
		selectedItemChanged,
		openMenu,
		compactMode,
		onMenuReady,
	} = props;

	const { user } = useAuth();
	const userPermissions = getUserPermissions(user);

	const navigate = useNavigate();
	function normalizePath() {
		const navItems = navigation(userPermissions);
		return navItems.map((item) => ({
			...item,
			//expanded: isLarge, // esta linea expande todos los nodos si se necesita
			path:
				item.path && !item.path.startsWith('/')
					? `/${item.path}`
					: item.path,
		}));
	}

	const items = useMemo(
		normalizePath,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const {
		navigationData: { currentPath },
	} = useNavigation();

	const [selectedPath, setSelectedPath] = useState(currentPath);

	const treeViewRef = useRef<TreeView>(null);
	const wrapperRef = useRef<HTMLDivElement>();
	const getWrapperRef = useCallback(
		(element: HTMLDivElement) => {
			const prevElement = wrapperRef.current;
			if (prevElement) {
				events.off(prevElement, 'dxclick');
			}

			wrapperRef.current = element;
			events.on(element, 'dxclick', (e: React.PointerEvent) => {
				openMenu(e);
			});
		},
		[openMenu]
	);

	useEffect(() => {
		const treeView = treeViewRef.current?.instance;
		if (!treeView) {
			return;
		}

		if (currentPath !== undefined) {
			treeView.selectItem(currentPath);
			treeView.expandItem(currentPath);
		}

		if (compactMode) {
			treeView.collapseAll();
		}
	}, [currentPath, compactMode]);

	const handleItemClick = (e: any) => {
		const newPath = e.itemData.path;
		if (newPath === selectedPath) {
			// Forzar la recarga si se hace clic en la misma ruta
			navigate('/');
			setTimeout(() => navigate(newPath), 0);
		} else {
			setSelectedPath(newPath);
			selectedItemChanged(e);
		}
	};

	return (
		<div
			className={
				'dx-swatch-additional bg-transparent side-navigation-menu animate__animated animate__fadeInLeft'
			}
			ref={getWrapperRef}
		>
			{children}
			<div className={'menu-container'}>
				<TreeView
					ref={treeViewRef}
					items={items}
					keyExpr={'path'}
					selectionMode={'single'}
					focusStateEnabled={false}
					expandEvent={'click'}
					onItemClick={handleItemClick}
					onContentReady={onMenuReady}
					width={'100%'}
					searchEnabled={false}
					searchMode="contains"
				/>
			</div>
		</div>
	);
}
