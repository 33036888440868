import DataGrid, {
	Grouping,
	GroupPanel,
	FilterPanel,
	HeaderFilter,
	Export,
	Scrolling,
	RemoteOperations,
	ColumnFixing,
	ColumnChooser,
} from 'devextreme-react/data-grid';
import { useContext } from 'react';
import 'devextreme/data/odata/store';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '../../utils/showNotification';
import { useAuth } from '../../contexts/auth';
import { UiConfigContext } from '../../contexts/uiConfig';
import { apiBaseUrl } from '../../config/globalVariables';
import { MasterDetailProps } from './masterOperationsDetailsView';

export const DataGridDetails = (props: MasterDetailProps) => {
	const navigate = useNavigate();
	const { signOut } = useAuth();
	const { uiConfig } = useContext(UiConfigContext);
	console.log('operationtype: ', props.data.data.operationType);

	const operationDetailSource = {
		store: {
			version: 4,
			type: 'odata',
			key: 'detailId',
			url: `${apiBaseUrl}operationdetails?finnektaCode=${props.data.key}`,
			errorHandler: function (error: any) {
				console.log(error.httpStatus);
				if (error.httpStatus === 401) {
					showNotification({
						message:
							'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
						type: 'error',
						displayTime: 12000,
						position: uiConfig.notificationsPosition,
					});
					signOut();
				}
				if (error.httpStatus === 403) {
					navigate('/forbidden');
				}
			},
		},
		select: [
			'detailId',
			'type',
			'consultType',
			'originAccount',
			'destinationAccount',
			'providerName',
			'billNumber',
			'concept',
			'originBank',
			'destinationBank',
		],
	};

	function getColumns(operationType: string) {
		switch (operationType) {
			case 'GET_ACCOUNT_MOVEMENTS':
				return [
					{ dataField: 'originBank', caption: 'Banco de origen' },
					{
						dataField: 'destinationBank',
						caption: 'Banco de destino',
					},
				];
			case 'GET_ACCOUNTS':
				return [
					{ dataField: 'consultType', caption: 'Tipo de consulta' },
				];
			default:
				return [];
		}
	}

	const Columns = getColumns(props.data.data.operationType);

	return (
		<DataGrid
			dataSource={operationDetailSource as any}
			columns={Columns}
			showBorders={false}
			focusedRowEnabled={false}
			columnAutoWidth={true}
			columnHidingEnabled={true}
			rowAlternationEnabled={true}
			hoverStateEnabled={true}
			allowColumnReordering={true}
			allowColumnResizing={true}
			width="100%"
			className="dx-datagrid-filter-top"
			columnResizingMode="widget"
		>
			<Scrolling mode="standard" />
			<GroupPanel visible={false} />
			<FilterPanel visible={false} />
			<HeaderFilter visible={false} />
			<ColumnFixing enabled={false} />
			<ColumnChooser enabled={false} />
			<Grouping autoExpandAll={true} />
			<RemoteOperations
				filtering={true}
				paging={true}
				sorting={true}
				summary={false}
				grouping={false}
				groupPaging={false}
			/>
			<Export enabled={false} />
		</DataGrid>
	);
};
