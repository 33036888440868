import React, { useState, useRef, useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form, {
	Item,
	Label,
	ButtonItem,
	ButtonOptions,
	RequiredRule,
	CustomRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { ValidationCallbackData } from 'devextreme-react/common';
import { changePassword } from '../../api/auth';
import { defaultShowTimeSToast } from '../../config/globalVariables';
import { useAddAppLoginClass } from '../form-commons/FormCommons';
import { showNotification } from '../../utils/showNotification';
import { UiConfigContext } from '../../contexts/uiConfig';

export default function ChangePasswordForm() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const formData = useRef({ password: '' });
	const { recoveryCode } = useParams();
	const { uiConfig } = useContext(UiConfigContext);
	const onSubmit = useCallback(
		async (e: any) => {
			e.preventDefault();
			const { password } = formData.current;
			setLoading(true);

			const result = await changePassword(password, recoveryCode);
			setLoading(false);

			if (result.isOk) {
				navigate('/login');
			} else {
				showNotification({ message: result.message!, type: 'error', displayTime: defaultShowTimeSToast, position: uiConfig.notificationsPosition });
			}
		},
		[navigate, recoveryCode]
	);

	const confirmPassword = useCallback(
		({ value }: ValidationCallbackData) =>
			value === formData.current.password,
		[]
	);

	// efecto para sobreescribir el background del login con el general
	useAddAppLoginClass();

	return (
		<form onSubmit={onSubmit}>
			<Form formData={formData.current} disabled={loading}>
				<Item
					dataField={'password'}
					editorType={'dxTextBox'}
					editorOptions={passwordEditorOptions}
				>
					<RequiredRule message="Contraseña es requerida" />
					<Label visible={false} />
				</Item>
				<Item
					dataField={'confirmedPassword'}
					editorType={'dxTextBox'}
					editorOptions={confirmedPasswordEditorOptions}
				>
					<RequiredRule message="Contraseña es requerida" />
					<CustomRule
						message={'Las contraseñas no coinciden'}
						validationCallback={confirmPassword}
					/>
					<Label visible={false} />
				</Item>
				<ButtonItem>
					<ButtonOptions
						width={'100%'}
						type={'default'}
						useSubmitBehavior={true}
					>
						<span className="dx-button-text">
							{loading ? (
								<LoadIndicator
									width={'24px'}
									height={'24px'}
									visible={true}
								/>
							) : (
								'Continue'
							)}
						</span>
					</ButtonOptions>
				</ButtonItem>
			</Form>
		</form>
	);
}

const passwordEditorOptions = {
	stylingMode: 'filled',
	placeholder: 'Contraseña',
	mode: 'password',
};
const confirmedPasswordEditorOptions = {
	stylingMode: 'filled',
	placeholder: 'Confirmar contraseña',
	mode: 'password',
};
