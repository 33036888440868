import { Routes, Route, Navigate } from 'react-router-dom';
import { SingleCard } from './layouts';
import {
	FirstLoginForm
} from './components';

export default function FirstLoginContent() {
	return (
		<Routes>
			<Route
				path="/first-login"
				element={
					<SingleCard>
						<FirstLoginForm/>
					</SingleCard>
				}
			/>
            <Route path="*" element={<Navigate to={'/first-login'} />}></Route>
		</Routes>

	);
}
