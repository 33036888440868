import {
	HomePage,
	ProfilePage,
	Schemas,
	ApiKeys,
	ApiKeysPermissions,
	NewApiKeyForm,
	EditApiKeyForm,
	Users,
	NewUserForm,
	EditUserForm,
	Roles,
	Operations,
	Forbidden,
	Unauthorized,
	NotFound,
	ApiKeysCreated,
	NewPasswordForm,
} from './pages';
import UnauthenticatedContent from './UnauthenticatedContent';
import { withNavigationWatcher } from './contexts/navigation';
import { getUserPermissions } from './config/actions';

export function getRoutes(user: any) {
	const userPermissions = getUserPermissions(user);

	const createRoute = (
		path: string,
		element: React.ComponentType,
		permissionCheck: boolean
	) => {
		return {
			path,
			element: permissionCheck ? element : Forbidden,
		};
	};

	const routes = [
		{ path: '/login', element: UnauthenticatedContent },
        { path: '/', element: HomePage },
		{ path: '/home', element: HomePage },
		{ path: '/configuracion', element: ProfilePage },
		{ path: '/documentation/open-banking/schemas', element: Schemas },
		{ path: '/roles', element: Roles },
		{ path: '/operations', element: Operations }, 

		createRoute('/apikeys', ApiKeys, userPermissions.canViewApiKeyList),
		createRoute(
			'/apikeys/permissions',
			ApiKeysPermissions,
			userPermissions.canViewApiKeyList
		),
		createRoute(
			'/apikeys/new',
			NewApiKeyForm,
			userPermissions.canViewApiKeyList && userPermissions.canCreateApiKey
		),
		createRoute(
			'/apikeys/created',
			ApiKeysCreated,
			userPermissions.canViewApiKeyList && userPermissions.canCreateApiKey
		),
		createRoute(
			'/apikeys/edit',
			EditApiKeyForm,
			userPermissions.canViewApiKeyList
		),
		createRoute('/users', Users, userPermissions.canViewUserList),
		createRoute(
			'/users/new',
			NewUserForm,
			userPermissions.canViewUserList && userPermissions.canCreateUser
		),
		createRoute(
			'/users/edit',
			EditUserForm,
			userPermissions.canViewUserList
		),
		{ path: '/new-password', element: NewPasswordForm},
		{ path: '/forbidden', element: Forbidden },
		{ path: '/unauthorized', element: Unauthorized },
		{ path: '/not-found', element: NotFound },
		{ path: '*', element: NotFound },
	];

	return routes.map((route) => ({
		...route,
		element: withNavigationWatcher(route.element, route.path),
	}));
}
