import { useState, useMemo, useEffect } from 'react';
import { TextBoxTypes } from 'devextreme-react/text-box';

export const usePasswordEditorOptions = () => {
    const [passwordMode, setPasswordMode] =
		useState<TextBoxTypes.TextBoxType>('password');

    // Usar useMemo para evitar recrear el objeto en cada renderizado
	let passwordEditorOptions = useMemo(
		() => ({
			stylingMode: 'outlined',
			labelMode: 'outside',
			mode: passwordMode, // Asigna directamente el estado que controla si es texto visible o password
			height: '50px',
			showClearButton: false,
			buttons: [
				{
					name: 'passwordVisibilityToggle',
					location: 'after',
					options: {
						icon: passwordMode === 'text' ? 'eyeopen' : 'eyeclose',
						onClick: () => {
							setPasswordMode(
								passwordMode === 'text' ? 'password' : 'text'
							);
						},
						elementAttr: {
							style: {
								backgroundColor: 'transparent',
								color: 'black',
								border: 'none !important',
								cursor: 'pointer',
								margin: 'none',
							},
						},
					},
				},
			],
		}),
		[passwordMode]
	);

    return passwordEditorOptions;
}


export const useConfirmPasswordEditorOptions = () => {
    const [confirmPasswordMode, setConfirmPasswordMode] =
		useState<TextBoxTypes.TextBoxType>('password');

    // Usar useMemo para evitar recrear el objeto en cada renderizado
	let confirmPasswordEditorOptions = useMemo(
		() => ({
			stylingMode: 'outlined',
			labelMode: 'outside',
			mode: confirmPasswordMode, // Asigna directamente el estado que controla si es texto visible o password
			height: '50px',
			showClearButton: false,
			buttons: [
				{
					name: 'passwordVisibilityToggle',
					location: 'after',
					options: {
						icon: confirmPasswordMode === 'text' ? 'eyeopen' : 'eyeclose',
						onClick: () => {
							setConfirmPasswordMode(
								confirmPasswordMode === 'text' ? 'password' : 'text'
							);
						},
						elementAttr: {
							style: {
								backgroundColor: 'transparent',
								color: 'black',
								border: 'none !important',
								cursor: 'pointer',
								margin: 'none',
							},
						},
					},
				},
			],
		}),
		[confirmPasswordMode]
	);

    return confirmPasswordEditorOptions;
}


export const useAddAppLoginClass = () => {
	// efecto para sobreescribir el background del login con el general
	useEffect(() => {
		// Cuando el componente se monta, agrega la clase
		document.getElementById('app')?.classList.add('app-login');

		// Cuando el componente se desmonta, remueve la clase
		return () => {
			document.getElementById('app')?.classList.remove('app-login');
		};
	}, []);
};